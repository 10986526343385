const setActiveHeaderMenu = () => {
  const activeSubmenuParent = document
    .querySelector(".header-links__submenu-link a.active")
    ?.closest(".header-links__dropdown")
  const activeSubmenuParentTitle = activeSubmenuParent?.querySelector(
    ".header-links__dropdown-title a"
  )

  const previouslyActive = document.querySelectorAll(
    ".header-links__dropdown-title .partially-active"
  )

  previouslyActive.forEach(element => {
    element.classList.remove("partially-active")
  })

  activeSubmenuParentTitle?.classList.add("partially-active")
}

const setActiveMobileMenu = () => {
  const activeSubmenu = document.querySelector(".accordion__item-link--active")
  const activeSubmenuParent = document
    .querySelector(".accordion__item-link--active")
    ?.closest(".accordion__item")
  const activeSubmenuParentTitle = activeSubmenuParent?.querySelector(
    ".accordion__item-title a"
  )

  const previouslyActive = document.querySelectorAll(
    ".accordion__item-title .partially-active"
  )

  previouslyActive.forEach(element => {
    element.classList.remove("partially-active")
  })

  activeSubmenuParentTitle?.classList.add("partially-active")

  sessionStorage.setItem(
    "sidebarActiveIndex",
    activeSubmenu?.dataset.parentIndex
  )
}

exports.shouldUpdateScroll = ({ routerProps }) => {
  const { state } = routerProps.location
  const paginated = document.getElementById("paginated")
  // Check against false here because we want scroll position to reset if  state.updateScroll === undefined
  if (state?.updateScroll === false && paginated) {
    const { top } = paginated.getBoundingClientRect()
    window.scrollTo(...[0, top + window.scrollY])
    return false
  }
  return true
}

exports.onRouteUpdate = () => {
  setActiveMobileMenu()
  setActiveHeaderMenu()
}
